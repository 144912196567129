/**
 *  Very lite javascript/styling for the login page
 */

import "./login/style.scss";

// Set up the redirect now before login
let redirectURL = "";
if (window.location.hash) {
    const redirect = window.location.hash.match(/\#redirect=(.*)/);
    if (redirect && redirect[1]) {
        redirectURL = atob(redirect[1]);
        console.log("Redirecting to " + redirectURL);
    }
}

// Auto login if they have existing valid credentials
if ("credentials" in navigator) {
    navigator.credentials.get({ password: true })
        .then((account: any) => {
            if (account && account.name && account.password) {
                submit(account.name, account.password);
            }
        });
}

// Taken from the polyfill for IE11
let ie11 = false;
if (!String.prototype.repeat) {
    ie11 = true;
    String.prototype.repeat = function (count) {
        'use strict';
        if (this == null) {
            throw new TypeError('can\'t convert ' + this + ' to object');
        }
        const str = '' + this;
        count = +count;
        if (count !== count) {
            count = 0;
        }
        if (count < 0) {
            throw new RangeError('repeat count must be non-negative');
        }
        if (count === Infinity) {
            throw new RangeError('repeat count must be less than infinity');
        }
        count = Math.floor(count);
        if (str.length === 0 || count === 0) {
            return '';
        }
        // Ensuring count is a 31-bit integer allows us to heavily optimize the
        // main part. But anyway, most current (August 2014) browsers can't handle
        // strings 1 << 28 chars or longer, so:
        if (str.length * count >= 1 << 28) {
            throw new RangeError('repeat count must not overflow maximum string size');
        }
        let rpt = '';
        for (let i = 0; i < count; i++) {
            rpt += str;
        }
        return rpt;
    };
}

// Create the "Signing In..." dot effect
let dots = 0;
let dotterTimer: number;
function dotter(): void {
    dots++;
    if (dots >= 4) {
        dots = 0;
    }
    const button = document.getElementById("submit") as HTMLButtonElement;
    button.value = "Signing In" + ".".repeat(dots);
    dotterTimer = setTimeout(dotter, 400);
}

function submit(username: string, password: string): void {
    try {
        const button = document.getElementById("submit") as HTMLButtonElement;
        dotter();
        button.disabled = true;
        const xmlhttp = new XMLHttpRequest();
        const data = "login=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&remember_me=on";
        xmlhttp.open("POST", "/login", true);
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        const onFail = () => {
            clearTimeout(dotterTimer);
            button.disabled = false;
            button.value = "Sign In";
        };
        xmlhttp.onerror = onFail;
        xmlhttp.onabort = onFail;
        xmlhttp.ontimeout = onFail;
        xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState === XMLHttpRequest.DONE && xmlhttp.status === 200) {
                const response = xmlhttp.responseText;
                console.log(xmlhttp, response);
                // Failed login
                // We check if response has gears_login, meaning server gave us back /login page
                if (response.indexOf("gears_login") >= 0) {
                    console.log("Failed Login");
                    if ("credentials" in navigator) {
                        if ("preventSilentAccess" in navigator.credentials) {
                            navigator.credentials.preventSilentAccess();
                        } else {
                            navigator.credentials.requireUserMediation();
                        }
                    }
                    window.location.reload();
                } else {
                    try {
                        const responseJSON = JSON.parse(response);
                        if (responseJSON.login && responseJSON.login === "disabled") {
                            // Account is disabled
                            clearTimeout(dotterTimer);
                            button.value = "Sign In";
                            button.disabled = false;
                            const existingFlash = document.getElementById("disabled-account");
                            if (existingFlash) {
                                existingFlash.remove();
                            }
                            const flash = document.createElement("div");
                            flash.id = "disabled-account";
                            flash.classList.add("flash");
                            flash.textContent = "Account is disabled, an administrator has been notified to re-activate your account.";
                            document.getElementsByTagName("fieldset")[0].childNodes[1].appendChild(flash);
                            return;
                        }
                    } catch (e) {
                        // Do nothing
                    }
                    if (!ie11) {
                        const ls = localStorage.getItem("previous-logins");
                        const previousLogins: IStoredLogin[] = ls ? JSON.parse(ls) : [];
                        if (!previousLogins.find((user) => user.email === username)) {
                            previousLogins.push({
                                name: username,
                                email: username,
                                initials: "",
                            });
                            localStorage.setItem("previous-logins", JSON.stringify(previousLogins));
                        }
                    }
                    clearTimeout(dotterTimer);
                    button.value = "Logged In";
                    // TODO: Fix this so firefox can auto login same as chrome
                    if ("credentials" in navigator && global.PasswordCredential) {
                        navigator.credentials.store(new PasswordCredential({
                            id: username,
                            name: username,
                            password,
                        })).then(() => {
                            if (redirectURL) {
                                console.log("Logged in, redirecting");
                                window.location.href = redirectURL;
                            } else {
                                console.log("Logged in, not redirecting");
                                window.location.href = xmlhttp.responseURL || "";
                            }
                        }).catch(() => {
                            if (redirectURL) {
                                console.log("Logged in, redirecting");
                                window.location.href = redirectURL;
                            } else {
                                console.log("Logged in, not redirecting");
                                window.location.reload();
                            }
                        });
                    } else {
                        if (redirectURL) {
                            console.log("Logged in, redirecting");
                            window.location.href = redirectURL;
                        } else {
                            console.log("Logged in, not redirecting");
                            window.location.href = xmlhttp.responseURL || "";
                        }
                    }
                }
            }
        };
        xmlhttp.send(data);
    } catch (e) {
        alert(e.toString());
        console.error(e);
    }
}

function restoreLoginScreen(): void {
    const fieldset = document.querySelector("#login-form fieldset") as HTMLElement;
    if (fieldset) {
        fieldset.style.display = "";
        (document.querySelector("#quick-login") as HTMLElement).remove();
        (document.querySelector("#submit") as HTMLElement).style.display = "";
    }
}


function quickLogin(e: React.MouseEvent<HTMLElement>): void {
    const fieldset = document.querySelector("#login-form fieldset") as HTMLElement;
    if (fieldset) {
        fieldset.style.display = "";
        (document.querySelector("#quick-login") as HTMLElement).remove();
        (document.querySelector("#submit") as HTMLElement).style.display = "";
        (document.querySelector("#email") as HTMLInputElement).value = e.currentTarget.dataset.email as string;
    }
}

interface IStoredLogin {
    name: string;
    email: string;
    initials: string;
}

window.addEventListener("load", () => {
    const ls = localStorage.getItem("previous-logins");
    const previousLogins: IStoredLogin[] = ls ? JSON.parse(ls) : [];
    if (!ie11 && previousLogins.length > 1) {
        const fieldset = document.querySelector("#login-form fieldset") as HTMLElement;
        if (fieldset) {
            fieldset.style.display = "none";
            const loginForm = document.querySelector("#login-form") as HTMLElement;
            (document.querySelector("#submit") as HTMLElement).style.display = "none";
            const containerDiv = document.createElement("div");
            containerDiv.id = "quick-login";
            previousLogins.map((user) => {
                const div = document.createElement("div");
                div.className = "user-account";
                div.innerText = user.name;
                div.onclick = quickLogin;
                div.dataset.email = user.email;
                containerDiv.append(div);
            });
            const div = document.createElement("div");
            div.className = "user-account";
            div.innerText = "New Login";
            div.onclick = restoreLoginScreen;
            containerDiv.append(div);
            loginForm.prepend(containerDiv);
        }
    }
    document.getElementById("login-form").addEventListener("submit", (e) => {
        e.preventDefault();
        try {
            // const form = new FormData(document.getElementById("login-form"));
            const username = document.getElementById("email").value; // form.get("login");
            const password = document.getElementById("password").value;
            if (username && password) {
                submit(username, password);
            }
        } catch (e2) {
            alert(e2.toString());
        }
        return false;
    });
});
